import { IOne2OneBuyer } from "@genericTypes/sharedTypes";
import React from "react";
import styles from "./styles.module.scss";

const BuyerCard = ({
    buyer,
    isSelected,
    handleBuyerClick,
}: {
    buyer: IOne2OneBuyer;
    isSelected: boolean;
    handleBuyerClick: (buyer: IOne2OneBuyer) => void;
}) => {
    return (
        <div
            className={`flex items-center justify-between bg-white ${styles.buyer} ${styles["selected-buyer"]}`}
            data-selected={isSelected}
            onClick={() => handleBuyerClick(buyer)}
        >
            <div className="flex items-center gap-2">
                <input
                    type="checkbox"
                    style={{ width: "15px", height: "15px" }}
                    checked={isSelected}
                    data-tf-element-role={`consent-opted-advertiser-input-${buyer.buyerId}`}
                />
                <label
                    data-tf-element-role={`consent-opted-advertiser-name-${buyer.buyerId}`}
                >
                    {buyer.displayName || buyer.buyerName}
                </label>
            </div>
            {buyer.logo && (
                <div className="w-full max-w-[100px]">
                    <img
                        src={buyer.logo}
                        alt={buyer.displayName || buyer.buyerName}
                        width={200}
                    />
                </div>
            )}
        </div>
    );
};

export default BuyerCard;
