import { gql } from "graphql-request";
export const BEST_OVERALL_DOMAIN_OFFER_QUERY = gql`
    query getBestOverallDomainOffers(
        $categoryId: Int
        $language: String!
        $domainId: Int
    ) {
        allDomainOffers(
            domainId: $domainId
            categoryId: $categoryId
            orderByDesc: weight
        ) {
            placements
            offers(isDeleted: false, isActive: true) {
                id
                languages
                title
                featuresListTitle
                description
                name
                slug
                url(isActive: true, isDeleted: false, languageEq: $language) {
                    isMain
                    main {
                        url
                    }
                    url
                }
                translations {
                    key
                    value
                    language
                }
                attributes(isDeleted: false) {
                    value
                    id
                    translations {
                        key
                        value
                        language
                    }
                    baseAttribute {
                        weight
                        comparison
                        name
                        hidden
                        group
                        categoryId
                        groupSlug
                        forceComparisonsDisplay
                        attributesGroup {
                            id
                            slug
                            order
                            name
                            isActive
                        }
                        translations {
                            key
                            value
                            language
                        }
                    }
                }
                features(languageEq: $language) {
                    id
                    type
                    description
                    translations {
                        key
                        value
                        language
                    }
                }
                logo {
                    path
                    fullPath
                }
            }
        }
    }
`;
