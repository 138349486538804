import { gql } from "graphql-request";
export const BEST_OVERALL_GET_CATEGORY_BY_ID = gql`
    query bestOverallGetCategoryById($id: Int, $language: String!) {
        category(id: $id, isDeleted: false) {
            id
            name
            label
            slug
            languages
            TCPAMessage
            isOneToOneConsent
            url(isActive: true, isDeleted: false, languageEq: $language) {
                isMain
                main {
                    url
                }
                url
            }
            metatags {
                key
                value
            }
            translations {
                key
                value
                language
            }
            mainImage {
                path
                fullPath
            }
        }
    }
`;
