import React, { ReactElement, useState } from "react";
import Text from "@components/shared/text";
import styles from "./style.module.scss";
import Button from "@components/shared/form/button";
import CustomSelect from "@components/shared/customSelect";
import {
    GQLAllDomainFormsQuery,
    GQLCategoryByIdQuery,
    GQLCrossSellingDomainFormQuery,
    GQLCrossSellingDomainFormQueryVariables,
} from "@gql/types/gql";
import { QueryClient, useQuery } from "@tanstack/react-query";
import Form from "@components/shared/form";
import { fetchDataFromFront } from "@gql/graphQLRequestServer";
import { CATEGORY_BY_ID } from "@gql/queries/getFormByCategoryById";
import Spinner from "@components/shared/spinner";
import { SelectedCategory } from "@genericTypes/form-store-types";
import { useAtom } from "jotai";

import { selectedCategoryAtom } from "@store/formStore";
import {
    FormActionTypes,
    ICustomDomain,
    TranslationsObject,
} from "@genericTypes/sharedTypes";
import { translate } from "@helpers/translationObjectFormatter";
import { CROSS_SELLING_DOMAIN_FORM } from "@gql/queries/crossSellingDomainFormQuery";
import { useDispatch } from "@components/shared/form/formReducer/FormReducer";
import { DEFAULT_OPTIONS } from "@lib/reactQueryDefaultOptions";

interface Options {
    id: number;
    label: string;
    name: string;
    slug: string;
    tcpaMessage: String;
    isOneToOneConsent: boolean;
}
interface vars {
    slug?: string;
    id?: number;
    domainId?: number;
}

export default function GenericForm(): ReactElement {
    const { data: categoriesWithForms } = useQuery<
        GQLAllDomainFormsQuery,
        Error
    >({
        queryKey: ["categoriesWithForms"],
    });

    const domainId = useQuery<ICustomDomain>({
        queryKey: ["domain"],
    }).data?.domain?.id;
    const { data: translations } = useQuery<TranslationsObject>([
        "translations",
    ]);
    const queryClient = new QueryClient(DEFAULT_OPTIONS);
    const [formData, setFormData] = useState<GQLCategoryByIdQuery | null>(null);
    const [loading, setLoading] = useState(false);
    const [, setGlobalSelectedCategory] = useAtom(selectedCategoryAtom);
    const dispatch = useDispatch();
    const [options] = useState<Options[]>(initCategoriesWithForms());

    const [selectedCategory, setSelectedCategory] =
        useState<SelectedCategory | null>(null);
    const [crossSelling, setCrossSelling] =
        useState<GQLCrossSellingDomainFormQuery | null>(null);

    function initCategoriesWithForms(): Options[] {
        if (
            categoriesWithForms &&
            categoriesWithForms.allDomainForms &&
            categoriesWithForms.allDomainForms.length
        ) {
            const tempOptions = categoriesWithForms.allDomainForms
                .filter((form) => form?.category)
                .map((item) => {
                    const option = {
                        id: item?.category?.id as number,
                        label: item?.category?.label as string,
                        name: item?.category?.name as string,
                        slug: item?.category?.slug as string,
                        tcpaMessage: item?.category?.TCPAMessage as string,
                        isOneToOneConsent: item?.category
                            ?.isOneToOneConsent as boolean,
                    };
                    return option;
                });
            return tempOptions;
        }
        return [];
    }

    const fetchFormData = async () => {
        try {
            setLoading(true);
            dispatch({
                type: FormActionTypes.SET_TCPA_MESSAGE,
                payload: selectedCategory?.tcpaMessage as string,
            });
            setGlobalSelectedCategory(selectedCategory);
            await queryClient.resetQueries(["isOneToOneConsent"]);
            const form = await queryClient.fetchQuery({
                queryKey: ["activeForm", selectedCategory?.slug],
                queryFn: async () => {
                    const data = await fetchDataFromFront<
                        GQLCategoryByIdQuery,
                        vars
                    >(CATEGORY_BY_ID, {
                        id: selectedCategory?.id,
                        domainId: domainId as number,
                    })();
                    return data;
                },
            });
            const crossSellingRes = await queryClient.fetchQuery({
                queryKey: ["crossSelling"],
                queryFn: async () => {
                    const data = await fetchDataFromFront<
                        GQLCrossSellingDomainFormQuery,
                        GQLCrossSellingDomainFormQueryVariables
                    >(CROSS_SELLING_DOMAIN_FORM, {
                        id: domainId,
                        categoryId: selectedCategory?.id,
                    })();
                    return data;
                },
            });
            if (form) {
                setFormData(form);
                dispatch({
                    type: FormActionTypes.IS_ONE_TO_ONE_CONSENT,
                    payload: selectedCategory?.isOneToOneConsent,
                });
                dispatch({
                    type: FormActionTypes.SET_STEP_TO_SHOW_BACK_BTN,
                    payload: 1,
                });
                dispatch({
                    type: FormActionTypes.SET_MODAL_VISIBILITY,
                    payload: true,
                });
            } else {
                setFormData(null);
            }
            if (crossSellingRes) {
                setCrossSelling(crossSellingRes);
            } else {
                setCrossSelling(null);
            }
            setLoading(false);
        } catch (error) {
            setFormData(null);
            setLoading(false);
        }
    };

    return (
        <>
            {options.length > 0 && (
                <div
                    className={`${styles.genericForm} w-[90%] sm:w-full p-10 mt-10 max-w-7xl px-5 mx-auto rounded-lg  `}
                >
                    <div>
                        <Text
                            type="title"
                            className="text-[20px] sm:text-3xl text-center"
                        >
                            {translate(
                                translations,
                                "several_homePage_formSection_title",
                            )}
                        </Text>
                        <Text className="text-center  mb-10 text-base lg1048:text-xl">
                            {translate(
                                translations,
                                "several_homePage_formSection_subTitle",
                            )}
                        </Text>
                    </div>

                    <div
                        className={`w-full flex justify-center gap-4 items-start flex-col sm:flex-row lg1048:flex-row`}
                    >
                        <div className="w-full mb-[3.5rem] relative flex flex-col md:max-w-sm lg1048:mb-0">
                            <CustomSelect
                                options={options?.map((el) => ({
                                    id: el.id,
                                    label: el.label ?? el.name ?? "",
                                    slug: el.slug ?? "",
                                    tcpaMessage: el.tcpaMessage,
                                    isOneToOneConsent: el.isOneToOneConsent,
                                }))}
                                placeHolder={translate(
                                    translations,
                                    "several_general_category_select_placeholder",
                                )}
                                className="w-full border-[2px] border-[#1693F0]"
                                onChange={setSelectedCategory}
                            />
                        </div>
                        <div className={`w-full ${styles.btnWidth}`}>
                            <Button
                                disabled={selectedCategory === null}
                                className="py-[13px] font-bold"
                                onClick={() => fetchFormData()}
                            >
                                {loading ? (
                                    <Spinner
                                        size={25}
                                        color="white"
                                        success={false}
                                    />
                                ) : (
                                    translate(
                                        translations,
                                        "several_general_getQuoteButton",
                                    )
                                )}
                            </Button>
                        </div>
                    </div>
                    <div hidden>
                        <Form
                            shouldSplit
                            formData={formData}
                            crossSellingProp={crossSelling}
                        />
                    </div>
                </div>
            )}
        </>
    );
}
