import { gql } from "graphql-request";
export const REVIEW_GET_CATEGORY_BY_ID = gql`
    query reviewGetCategoryById($id: Int, $language: String!) {
        category(id: $id, isDeleted: false) {
            id
            name
            label
            slug
            languages
            isOneToOneConsent
            translations {
                key
                value
                language
            }
            TCPAMessage
            url(isActive: true, isDeleted: false, languageEq: $language) {
                isMain
                main {
                    url
                }
                url
            }
        }
    }
`;
