import { getAccessToken } from "@lib/shared/cookies";
import HTTP, { apiHandler } from "@services/http.service";
import { AxiosRequestConfig } from "axios";

export interface Buyer {
    buyerId: number;
    buyerName: string;
    displayName: string;
    logo: string;
}

export interface OperationsResponse {
    success: boolean;
    data: {
        status: "PENDING" | "SUCCESS" | "FAILED";
        operationKey: "FETCH_BUYERS" | "ROUND_ONE" | "ROUND_TWO";
        buyers: Buyer[];
    } | null;
}
export const fetchLeadBuyers = async (
    id: string,
    options: {
        maxRetries?: number;
        retryDelay?: number;
        currentRetry?: number;
    } = {},
): Promise<{
    data: OperationsResponse["data"] | null;
    error: Error | null;
}> => {
    const { maxRetries = 60, retryDelay = 1000, currentRetry = 0 } = options;
    const accessToken = getAccessToken();
    const config: AxiosRequestConfig = {
        method: "post",
        url: `/api/operations`,
        data: {
            operationId: id,
        },
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken as string}`,
        },
    };
    const { data: operationsResponse, error } =
        await apiHandler<OperationsResponse>(() => HTTP.client(config));
    // If status is pending and we haven't exceeded max retries, retry
    if (
        operationsResponse?.data?.status === "PENDING" &&
        currentRetry < maxRetries
    ) {
        // Wait for the specified delay before retrying
        await new Promise((resolve) => setTimeout(resolve, retryDelay));
        // Recursive call with incremented retry count
        return fetchLeadBuyers(id, {
            maxRetries,
            retryDelay,
            currentRetry: currentRetry + 1,
        });
    }
    // Return the response or null
    return {
        data: operationsResponse?.data ?? null,
        error,
    };
};
