import {
    FormActionTypes,
    IOne2OneBuyer,
    TranslationsObject,
} from "@genericTypes/sharedTypes";
import React, { useEffect } from "react";
import { useDispatch, useFormState } from "../formReducer/FormReducer";
import BuyerCard from "./BuyerCard";
import buttonStyles from "../step/styles.module.scss";
import styles from "./styles.module.scss";
import Spinner from "@components/shared/spinner";
import { useQuery } from "@tanstack/react-query";
import { translate } from "@helpers/translationObjectFormatter";

const SecondRoundBuyers = ({
    buyers,
    buttonText = "Submit",
    buttonLoading = false,
}: {
    buyers: IOne2OneBuyer[];
    buttonText: string;
    buttonLoading: boolean;
}) => {
    const { data: translations } = useQuery<TranslationsObject>([
        "translations",
    ]);
    const dispatch = useDispatch();
    const [formState] = useFormState();
    // const [isAllSelected,selectAllBuyers ] = useState(true)

    useEffect(() => {
        dispatch({
            type: FormActionTypes.SET_BUYERS,
            payload: [
                ...formState.firstRoundBuyers,
                ...formState.secondRoundBuyers,
            ],
        });
    }, [formState.showBuyersList]);

    const isBuyerSelected = (buyer: IOne2OneBuyer) => {
        return formState.buyers.find((b) => b.buyerId === buyer.buyerId);
    };

    const handleBuyerClick = (buyer: IOne2OneBuyer) => {
        if (isBuyerSelected(buyer)) {
            const newBuyers = formState.buyers.filter(
                (b) => b.buyerId !== buyer.buyerId,
            );
            dispatch({
                type: FormActionTypes.SET_BUYERS,
                payload: newBuyers,
            });
            return;
        }
        dispatch({
            type: FormActionTypes.SET_BUYERS,
            payload: [...formState.buyers, buyer],
        });
    };

    const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            dispatch({
                type: FormActionTypes.SET_BUYERS,
                payload: [
                    ...formState.firstRoundBuyers,
                    ...formState.secondRoundBuyers,
                ],
            });
            return;
        }
        dispatch({
            type: FormActionTypes.SET_BUYERS,
            payload: [...formState.firstRoundBuyers],
        });
    };

    return (
        <div className="flex flex-col gap-5 w-full">
            <span className="font-extrabold text-2xl">
                {translate(translations, "several_form_second_round_title")}
            </span>
            <span className="font-normal text-lg">
                {translate(translations, "several_form_second_round_sub_title")}
            </span>
            <div className="flex items-center gap-2">
                <input
                    id="select_all"
                    type="checkbox"
                    style={{ width: "15px", height: "15px" }}
                    onChange={(e) => handleSelectAll(e)}
                    checked={
                        [
                            ...formState.firstRoundBuyers,
                            ...formState.secondRoundBuyers,
                        ].length === formState.buyers.length
                    }
                />
                <label htmlFor="select_all">
                    {translate(
                        translations,
                        "several_form_second_round_select_all_label",
                    )}
                </label>
            </div>
            {buyers.map((buyer) => (
                <BuyerCard
                    buyer={buyer}
                    key={buyer.buyerId}
                    isSelected={!!isBuyerSelected(buyer)}
                    handleBuyerClick={handleBuyerClick}
                />
            ))}
            <div className="w-full">
                <button
                    className={`${buttonStyles["nextBtn"]} ${styles["nextBtn"]} h-[3rem] font-bold`}
                    type="submit"
                    value={buttonText}
                    id="submitBtn"
                    data-tf-element-role="submit"
                    disabled={
                        buttonLoading ||
                        formState.buyers.length ===
                            formState.firstRoundBuyers.length
                    }
                >
                    {buttonLoading ? (
                        <Spinner size={25} color="white" success={false} />
                    ) : (
                        buttonText
                    )}
                </button>
            </div>
        </div>
    );
};

export default SecondRoundBuyers;
