import React, { useEffect, useMemo, useState } from "react";
import { IOne2OneBuyer } from "@genericTypes/sharedTypes";
import Modal from "@components/shared/modal";
import { showModal } from "@store/categoryStore";
import { useAtom } from "jotai";
import Portal from "src/portals/Portal";

const TCPA = ({
    tcpaMessage,
    buyers,
    btnText = "Get My Free Quote Now",
}: {
    tcpaMessage: string;
    buyers?: IOne2OneBuyer[];
    btnText?: string;
}) => {
    const [modalVisibility, setModalVisibility] = useAtom(showModal);
    const [tcpaModalContent, setTcpaModalContent] = useState<string | null>(
        null,
    );
    const selectedBuyers = useMemo(() => {
        return [
            "Several.com",
            buyers?.map((item) => item.displayName || item.buyerName),
        ].join(",");
    }, [buyers]);

    useEffect(() => {
        const partnersEl = document.getElementById("tcpa-Modal");
        const popupContent = partnersEl?.getElementsByTagName("span")[0];
        popupContent?.remove();

        if (popupContent) {
            setTcpaModalContent(popupContent?.innerHTML.trim());
        }

        partnersEl?.addEventListener("click", () => {
            setModalVisibility(true);
        });

        return () => {
            partnersEl?.removeEventListener("click", () => {
                setModalVisibility(true);
            });
        };
    }, []);

    return (
        <>
            {modalVisibility && (
                <Portal>
                    <Modal
                        showOuterClose={false}
                        closeIconClassName="absolute"
                        outerClassName="flex items-center justify-center h-[90vh] m-auto"
                        className="min-w-[400px] !h-fit p-8 rounded relative"
                        title="Our Partners"
                    >
                        <div
                            dangerouslySetInnerHTML={{
                                __html: tcpaModalContent as string,
                            }}
                        />
                    </Modal>
                </Portal>
            )}
            <input type="hidden" id="leadid_tcpa_disclosure"></input>
            <label htmlFor="leadid_tcpa_disclosure">
                <div
                    data-tf-element-role="consent-language"
                    className="text-[9px] mt-3 text-[gray]"
                    id="tcpa-message"
                    dangerouslySetInnerHTML={{
                        __html: tcpaMessage
                            .replace(
                                "${btnText}",
                                `<span data-tf-element-role="submit-text" \>${btnText}</span>`,
                            )
                            .replace(
                                "${buyers}",
                                selectedBuyers
                                    .split(",")
                                    ?.map(
                                        (item) =>
                                            `<span style="font-weight: 700" data-tf-element-role="consent-advertiser-name">${item}</span>`,
                                    )
                                    ?.join(", "),
                            ),
                    }}
                />
            </label>
        </>
    );
};

export default TCPA;
