import React, { Dispatch, memo, ReactElement, useEffect } from "react";
import { useAtom } from "jotai";
import styles from "./styles.module.scss";
import { formsAtom, addFieldAtom } from "@store/formStore";
import { UseFormRegister, UseFormSetValue } from "react-hook-form";
import { Field, SubmitLeadData } from "@genericTypes/sharedTypes";
import { handleFieldMasking } from "@helpers/formHelpers/form-masking";
import { softValidation } from "@helpers/formHelpers/soft-validation-field-helper";
import {
    AllForms,
    ErrorField,
    FormErrors,
} from "@genericTypes/form-store-types";
import { getSharedValueFromLocalStorage } from "@helpers/formHelpers/getSharedValueFromLocalStorage";
import formStyle from "../style.module.scss";
// import { useFormState } from "../formReducer/FormReducer";

const Input = ({
    field,
    register,
    setValue,
    formId,
    inputAction,
    stateErrors,
    setFieldChanged,
    questionAsTitle,
    isFirstStep,
    formErrors,
    setError,
}: {
    field: Field;
    register: UseFormRegister<{}>;
    setValue: UseFormSetValue<{}>;
    formId: number;
    stateErrors: FormErrors | null;
    errorsFromValidation?: boolean;
    inputAction?: (data: boolean) => Promise<{
        data: SubmitLeadData | null;
        error: Error | null;
    }>;
    setFieldChanged: Dispatch<React.SetStateAction<boolean>>;
    questionAsTitle: boolean | undefined;
    isFirstStep: boolean;
    formErrors: FormErrors | null;
    setError: (name: string, error: ErrorField | null) => void;
}): ReactElement => {
    const [form] = useAtom<AllForms>(formsAtom);
    const [, setForm] = useAtom(addFieldAtom);

    const change = (e: React.FormEvent<HTMLInputElement>): void => {
        // set field atom with data
        const { name } = e.currentTarget;
        let value = e.currentTarget.value.trimStart();
        if (setFieldChanged) setFieldChanged(true);
        if (formErrors?.[name]) {
            setError(name, null);
        }
        if (field.mask || field.origin.mask) {
            value = handleFieldMasking(
                field.mask ?? (field.origin.mask as "us-phone" | "zipCode"),
                value,
            );
        }
        setValue(field.origin.codeName as never, value as never, {
            shouldDirty: true,
            shouldTouch: true,
        });
        // call set form ... check formStore.js to see implementation.
        setForm({
            codeName: name,
            value: value,
            formId: formId,
            changed: true,
        });
    };

    const onLoad = () => {
        // check if global form state has the specified object and load it on UI
        const val = getSharedValueFromLocalStorage(field.origin.codeName);
        if (val && val.length > 0) {
            setValue(field.origin.codeName as never, val as never, {
                shouldDirty: true,
                shouldTouch: true,
            });
            setForm({
                codeName: field.origin.codeName,
                value: val,
                formId: formId,
                changed: true,
            });
        } else {
            if (
                form &&
                form[formId] &&
                form[formId]?.data[field.origin.codeName]
            ) {
                // set value is a function provided from use form hook.
                setValue(
                    field.origin.codeName as never,
                    form[formId].data[field.origin.codeName].value as never,
                    {
                        shouldDirty: true,
                        shouldTouch: true,
                    },
                );
            } else {
                setValue(field.origin.codeName as never, "" as never, {
                    shouldDirty: false,
                    shouldTouch: false,
                });
            }
        }
    };

    // TODO: test the below dispatch on load -> my theory is the stateErrors object will always be empty on load as it should be and the entire dispatch is useless
    useEffect(() => {
        // this is used when the form has errors after submission
        if (stateErrors) {
            Object.keys(stateErrors).map((item) => {
                setError(item, stateErrors[item]);
            });
        }
        onLoad();
    }, []);

    const blur = async (e: React.FocusEvent<HTMLInputElement, Element>) => {
        const { value, name } = e.currentTarget;
        const validation = await softValidation({
            field: field,
            fieldValue: value,
        });
        if (validation && validation?.type === "error") {
            setError(name, {
                codeName: name,
                message: validation.value as string,
            });
        } else if (validation && validation?.type === "valid") {
            setError(name, null);
        }
        if (value && value !== "" && inputAction) {
            void inputAction(true);
        }
    };

    const one2oneConsentAttribute: Record<string, Record<string, string>> = {
        email: {
            "data-tf-element-role": "consent-grantor-email",
        },
        phoneNumber: {
            "data-tf-element-role": "consent-grantor-phone",
        },
        firstName: {
            "data-tf-element-role": "consent-grantor-name",
        },
        lastName: {
            "data-tf-element-role": "consent-grantor-name",
        },
    };

    return (
        <div className="mb-5 w-full" key={field.origin.codeName}>
            <div
                className={
                    isFirstStep
                        ? "mb-3 font-bold text-center"
                        : questionAsTitle
                        ? `text-xl font-bold mb-[2rem] flex justify-center`
                        : `mb-3 flex`
                }
            >
                <label>
                    {field?.label ??
                        field.origin.label ??
                        field?.niceName ??
                        field.origin.niceName}
                </label>
            </div>
            <input
                type={field?.fieldType ?? field.origin.fieldType}
                {...register(field.origin.codeName as never, {
                    onChange: change,
                })}
                id={`${field.origin.codeName}`}
                onBlur={async (e) => await blur(e)}
                placeholder={
                    (field.placeholder as string) ?? field.origin.placeholder
                }
                className={`rounded-lg  w-full text-base  px-5 ${
                    formErrors && formErrors[field.origin.codeName]
                        ? styles["input-error"]
                        : styles["input"]
                }`}
                autoComplete="off"
                {...(one2oneConsentAttribute?.[field?.origin?.codeName]
                    ? one2oneConsentAttribute[field.origin.codeName]
                    : {})}
            />
            {(field?.note || field.origin.note) && (
                <div className={`flex ${formStyle.note}`}>
                    {field?.note ?? field.origin.note}
                </div>
            )}
            {formErrors?.[field.origin.codeName] && (
                <span
                    key={formErrors?.[field.origin.codeName]?.message}
                    style={{
                        display: "flex",
                        color: "red",
                        fontSize: "12px",
                    }}
                >
                    {formErrors?.[field.origin.codeName]?.message}
                </span>
            )}
        </div>
    );
};

function areEqual(
    prevState: Readonly<{
        field: Field;
        register: UseFormRegister<{}>;
        setValue: UseFormSetValue<{}>;
        formId: number;
        stateErrors: FormErrors | null;
        errorsFromValidation: boolean;
        inputAction?: (data: boolean) => Promise<{
            data: SubmitLeadData | null;
            error: Error | null;
        }>;
        setFieldChanged: Dispatch<React.SetStateAction<boolean>>;
        questionAsTitle: boolean | undefined;
        isFirstStep: boolean;
        formErrors: FormErrors | null;
        setError: (name: string, error: ErrorField | null) => void;
    }>,
    nextState: Readonly<{
        field: Field;
        register: UseFormRegister<{}>;
        setValue: UseFormSetValue<{}>;
        formId: number;
        stateErrors: FormErrors | null;
        errorsFromValidation: boolean;
        inputAction?: (data: boolean) => Promise<{
            data: SubmitLeadData | null;
            error: Error | null;
        }>;
        setFieldChanged: Dispatch<React.SetStateAction<boolean>>;
        questionAsTitle: boolean | undefined;
        isFirstStep: boolean;
        formErrors: FormErrors | null;
        setError: (name: string, error: ErrorField | null) => void;
    }>,
): boolean {
    return (
        prevState?.field.origin.codeName === nextState?.field.origin.codeName &&
        prevState.formErrors === nextState.formErrors
    );
}

export default memo(Input, areEqual);
