import { gql } from "graphql-request";
export const COMPARE_GET_CATEGORY_BY_ID = gql`
    query compareGetCategoryById($id: Int, $language: String!) {
        category(id: $id, isDeleted: false) {
            id
            name
            label
            slug
            languages
            TCPAMessage
            translations {
                key
                value
                language
            }
            mainImage {
                path
                fullPath
            }
            url(isActive: true, isDeleted: false, languageEq: $language) {
                isMain
                main {
                    url
                }
                url
            }
            metatags {
                key
                value
            }
            attributes(isDeleted: false, groupIsNull: false) {
                attributesGroup(isActive: true) {
                    name
                    slug
                    order
                    isActive
                    id
                }
                translations {
                    key
                    value
                    language
                }
            }
        }
    }
`;
