// import Spinner from "@components/shared/spinner";
import React, { ReactElement, ReactNode } from "react";
import CloseIcon from "./closeIcon";
import styles from "./style.module.scss";
import { useAtom } from "jotai";
import XIcon from "./xIcon";
import { showModal } from "@store/categoryStore";

interface Props {
    className?: string;
    outerClassName?: string;
    children: ReactNode;
    showOuterClose: boolean;
    onModalClose?: () => void;
    outerCloseButtonClassName?: string;
    closeIconClassName?: string;
    title?: string;
}

export default function Modal(props: Props): ReactElement {
    const [, setModalVisibility] = useAtom(showModal);
    const {
        className = "",
        children,
        showOuterClose,
        onModalClose,
        outerClassName,
        closeIconClassName,
        title,
    } = props;
    const showModalHandler = () => {
        setModalVisibility(false);
        if (onModalClose !== undefined) onModalClose();
    };

    return (
        <section
            className={`flex items-center justify-end ${styles["modalStyle"]} ${
                outerClassName || ""
            }`}
        >
            {showOuterClose && (
                <span
                    className={`${styles["closeIcon"]} rtl:rotate-180 ${
                        props.outerCloseButtonClassName || ""
                    }`}
                    onClick={showModalHandler}
                >
                    <CloseIcon />
                </span>
            )}

            <div className={`${styles["contentStyle"]} ${className ?? ""} `}>
                <div className="flex justify-end z-10 top-8 right-6">
                    <span
                        className={`cursor-pointer ${closeIconClassName || ""}`}
                        onClick={showModalHandler}
                    >
                        <XIcon />
                    </span>
                </div>
                {title && (
                    <div className="w-full font-bold text-2xl mb-8">
                        {title}
                    </div>
                )}
                {children}
            </div>
        </section>
    );
}
